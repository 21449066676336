console.log("ambiente", process.env.NODE_ENV);

const firmatechBaseUrl = process.env.NODE_ENV === 'development'
    ? `https://${window.location.hostname}:7000/api`
    : process.env.VUE_APP_API_FIRMATECH_BASE_URL;
const onboardingBaseUrl = process.env.NODE_ENV === 'development'
    ? `https://${window.location.hostname}:8080`
    : process.env.VUE_APP_ONBOARDING_BASE_URL;

const adminDigiyoUrl = process.env.VUE_APP_ADMIN_URL;

const digiyoBaseUrl = process.env.VUE_APP_DIGIYO_BASE_URL;
const digiyoApiKey = process.env.VUE_APP_DIGIYO_API_KEY;

const defaultTimeOut = 120000;
const delayCheck = 1500;

export {
    firmatechBaseUrl,
    onboardingBaseUrl,
    defaultTimeOut,
    delayCheck,
    adminDigiyoUrl,
    digiyoBaseUrl,
    digiyoApiKey
};
