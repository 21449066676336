import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import eventBus from '@/config/eventBus';
import { setBaseUrl, setInterceptors } from '@/config/axios';
import "./assets/fonts/DINRoundPro.css";
import config from 'devextreme/core/config';
import 'devextreme/dist/css/dx.fluent.saas.dark.css';
import esMessages from 'devextreme/localization/messages/es.json';
import { locale, loadMessages } from 'devextreme/localization';
import './assets/css/dx.override.style.css';

config({
    licenseKey: 'ewogICJmb3JtYXQiOiAxLAogICJjdXN0b21lcklkIjogIjVjZjgwNGYzLWVhYzAtNDI0NS1iYzY0LTU1ODFhMzY1NjU2ZCIsCiAgIm1heFZlcnNpb25BbGxvd2VkIjogMjQxCn0=.fb8ZYB/J+uUIdUJiiKh7auAxtPFw5Xs+0KpNAd8FGcPpcT+K50tWGB/Km0m8c++C3T5qkARIRz4el664iIaQz8i9HqzXC6eaz5sQUjutOM1MZ29TzN/Oy55RORBaW2CYJbpkLA==',
    editorStylingMode: 'filled', // filled or 'outlined' | 'underlined'
});

loadMessages(esMessages);
locale('es-PY');

const app = createApp(App);

app.config.globalProperties.$eventBus = eventBus;

setBaseUrl();
setInterceptors();

app.use(store)
    .use(router)
    .use(vuetify)
    .mount('#app');
