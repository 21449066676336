<template>
    <v-app-bar app flat>
        <v-app-bar-nav-icon @click="$emit('toggleRail')"></v-app-bar-nav-icon>
        <v-toolbar-title color="primary">Firmatech Backoffice</v-toolbar-title>
    </v-app-bar>
</template>

<script>
export default {
    name: 'AppBar',
};
</script>

<style scoped>
.v-toolbar {
    border-bottom: 1px solid rgba(var(--background-tailwind-dark-color), var(--border-toolbar-opacity)) !important;
}
</style>