<template>
    <v-container fluid
        class="d-flex align-items-center justify-center min-vh-100 animate__animated animate__backInDown">
        <v-row class="d-flex justify-center align-center">
            <v-col class="max-w-400" cols="12" sm="8" md="6" lg="4">
                <v-card class="pa-5 text-center" flat>
                    <div class="d-flex justify-center align-center mb-4">
                        <v-img :src="require('@/assets/img/logo-blue.svg')" height="150"></v-img>
                    </div>
                    <v-form ref="form" class="text-left">
                        <v-text-field class="mt-16 opacity-70" label="Usuario" v-model.trim="username"
                            @keyup.enter="login" :rules="[rules.required]" autofocus
                            prepend-inner-icon="mdi-account"></v-text-field>
                        <v-text-field class="mb-12" label="Contraseña" v-model.trim="password"
                            prepend-inner-icon="mdi-lock" :rules="[rules.required]" type="password"
                            @keyup.enter="login"></v-text-field>
                        <v-btn block class="opacity-100" variant="tonal" color="primary" @click="login" size="large"
                            :loading="authLoading">Ingresar</v-btn>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
        <DialogOk :message="message" :title="title" v-model:show="dialog"></DialogOk>
    </v-container>
</template>

<script>
import { authFirmatech } from '@/api/auth';
import DialogOk from '@/components/dialog/DialogOk';

export default {
    components: {
        DialogOk,
    },
    data() {
        return {
            username: '',
            password: '',
            authLoading: false,
            accessToken: null,
            refreshToken: null,
            dialog: false,
            message: '',
            title: '',
            rules: {
                required: value => !!value || 'Este campo es requerido.',
            }
        };
    },
    methods: {
        showMessage(message, title = "Info") {
            this.title = title;
            this.message = message;
            this.dialog = true;
        },
        async login() {
            const isValid = await this.$refs.form.validate();
            // Validar el formulario antes de continuar
            if (!isValid.valid) {
                return;
            }

            try {
                this.authLoading = true;

                const response = await authFirmatech(this.username, this.password);
                //console.log(response);

                this.accessToken = response.data.access_token;
                this.refreshToken = response.data.refresh_token;

                if (sessionStorage.getItem("access_token")) sessionStorage.removeItem("access_token");
                if (sessionStorage.getItem("refresh_token")) sessionStorage.removeItem("refresh_token");

                sessionStorage.setItem("access_token", this.accessToken);
                sessionStorage.setItem("refresh_token", this.refreshToken);

                this.$emit('login');
                this.$router.push('/');
            } catch (error) {
                if (error.response) {
                    if (error.response.status === 401) {
                        console.error("Unauthorized: ", error.response.data);
                        this.showMessage("Credenciales inválidas. Por favor, inténtelo de nuevo");
                    } else {
                        console.error("Error en la solicitud: ", error.response.data);
                        this.showMessage("Error en la solicitud. Por favor, inténtelo de nuevo más tarde");
                    }
                } else if (error.request) {
                    console.error("No se recibió respuesta del servidor: ", error.request);
                    this.showMessage("No se recibió respuesta del servidor. Por favor, inténtelo de nuevo más tarde");
                } else {
                    console.error("Error en la configuración de la solicitud: ", error.message);
                    this.showMessage("Error en la configuración de la solicitud. Por favor, inténtelo de nuevo más tarde");
                }
            } finally {
                this.authLoading = false;
            }
        }
    }
};
</script>

<style scoped>
.min-vh-100 {
    min-height: 100vh;
}

.max-w-400 {
    max-width: 400px;
}
</style>
