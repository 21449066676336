import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: () => import(/* webpackChunkName: "change-password" */ '../views/ChangePasswordView.vue')
    },
    {
        path: '/onboarding-report',
        name: 'onboarding-report',
        component: () => import(/* webpackChunkName: "onboarding-report" */ '../views/OnboardingReportView.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
    },
    {
        path: '/onboarding',
        name: 'onboarding',
        component: () => import(/* webpackChunkName: "onboarding" */ '../views/OnboardingView.vue')
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
