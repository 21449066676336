import axios from 'axios';

// Definir niveles de log con sus textos
const LOG_LEVEL = {
    TRACE: { value: 0, text: 'TRACE' },
    INFO: { value: 1, text: 'INFO' },
    WARN: { value: 2, text: 'WARN' },
    ERROR: { value: 3, text: 'ERROR' },
    FATAL: { value: 4, text: 'FATAL' }
};

// Nivel mínimo de log para enviar
const MIN_LOG_LEVEL = LOG_LEVEL.TRACE.value;

/**
 * Llama al API para guardar en el servidor los logs de manera asincrónica.
 * @param {string} message - Mensaje de log.
 * @param {object} logLevel - Objeto de nivel de log.
 * @param {uuid | null} onboardingId - Es un parámetro opcional, si se tiene el UUID de Firmatech.
 */
async function saveAsync({ message, logLevel, onboardingId }) {
    // Verificar si el nivel de log cumple con el mínimo
    if (logLevel.value < MIN_LOG_LEVEL) {
        return; // No enviar logs que no cumplan con el nivel mínimo
    }

    try {
        const dataRequest = {
            onboardingId,
            message,
            type: logLevel.text
        };

        await axios.post(`/log`, dataRequest);
    } catch (error) {
        console.error(
            `Error al guardar el log de tipo ${logLevel.text} en el servidor:`,
            error
        );
    }
}

/**
 * Extrae el mensaje de un objeto de error o devuelve la cadena directamente.
 * @param {string|Error} messageOrError - Mensaje de error u objeto de Error.
 * @returns {string} Mensaje extraído del error.
 */
function getMessageOnError(messageOrError) {
    if (typeof messageOrError === 'string') {
        return messageOrError;
    } else if (messageOrError instanceof Error) {
        return (
            messageOrError.stack || messageOrError.message || String(messageOrError)
        );
    } else {
        return String(messageOrError);
    }
}

/**
 * Almacena un mensaje de tipo TRACE en el servidor.
 * @param {string|Error} messageOrError - Mensaje de error u objeto de Error.
 * @param {uuid | null} onboardingId - Es un parámetro opcional, si se tiene el UUID de Firmatech.
 */
function trace(messageOrError, onboardingId = null) {
    const message = getMessageOnError(messageOrError);
    saveAsync({ message, logLevel: LOG_LEVEL.TRACE, onboardingId });
}

/**
 * Almacena un mensaje de tipo INFO en el servidor.
 * @param {string|Error} messageOrError - Mensaje de error u objeto de Error.
 * @param {uuid | null} onboardingId - Es un parámetro opcional, si se tiene el UUID de Firmatech.
 */
function info(messageOrError, onboardingId = null) {
    const message = getMessageOnError(messageOrError);
    saveAsync({ message, logLevel: LOG_LEVEL.INFO, onboardingId });
}

/**
 * Almacena un mensaje de tipo WARN en el servidor.
 * @param {string|Error} messageOrError - Mensaje de error u objeto de Error.
 * @param {uuid | null} onboardingId - Es un parámetro opcional, si se tiene el UUID de Firmatech.
 */
function warn(messageOrError, onboardingId = null) {
    const message = getMessageOnError(messageOrError);
    saveAsync({ message, logLevel: LOG_LEVEL.WARN, onboardingId });
}

/**
 * Almacena un mensaje de tipo ERROR en el servidor.
 * @param {string|Error} messageOrError - Mensaje de error u objeto de Error.
 * @param {uuid | null} onboardingId - Es un parámetro opcional, si se tiene el UUID de Firmatech.
 */
function error(messageOrError, onboardingId = null) {
    const message = getMessageOnError(messageOrError);
    saveAsync({ message, logLevel: LOG_LEVEL.ERROR, onboardingId });
}

/**
 * Almacena un mensaje de tipo FATAL en el servidor.
 * @param {string|Error} messageOrError - Mensaje de error u objeto de Error.
 * @param {uuid | null} onboardingId - Es un parámetro opcional, si se tiene el UUID de Firmatech.
 */
function fatal(messageOrError, onboardingId = null) {
    const message = getMessageOnError(messageOrError);
    saveAsync({ message, logLevel: LOG_LEVEL.FATAL, onboardingId });
}

const serverLog = {
    trace,
    info,
    warn,
    error,
    fatal
};

export default serverLog;
