<template>
    <v-container fluid class="animate__animated animate__fadeIn">
        <v-row>
            <v-col cols="12">
                <v-card flat class="card-img-home">
                    <v-img :src="require('@/assets/img/banner.svg')" alt="Bienvenido a Firmatech Back Office"
                        class="banner"></v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card class="pa-4 mb-4" flat>
                    <v-card-title>
                        <span class="headline">Firmatech Back Office</span>
                    </v-card-title>
                    <v-card-text>
                        <p>En Firmatech, nos dedicamos a proporcionar soluciones de back office eficientes y seguras
                            para facilitar la gestión de documentos y la incorporación de nuevos usuarios.</p>
                        <p><strong>Misión:</strong> Proveer un entorno seguro y eficiente para la gestión documental y
                            el onboarding de usuarios.</p>
                        <p><strong>Visión:</strong> Ser la plataforma líder en gestión de back office, reconocida por su
                            seguridad y eficiencia.</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="pa-4 mb-4" flat>
                    <v-card-title>
                        <span class="headline">Últimas Noticias</span>
                    </v-card-title>
                    <v-card-text>
                        <ul>
                            <li>Actualización 1.2.3 - Nuevas funcionalidades y mejoras.</li>
                            <li>Webinar: Cómo maximizar la eficiencia con Firmatech Back Office.</li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="pa-4" flat>
                    <v-card-title>
                        <span class="headline">Recursos Útiles</span>
                    </v-card-title>
                    <v-card-text>
                        <ul>
                            <li><a href="#">Preguntas Frecuentes</a></li>
                            <li><a href="#">Soporte Técnico</a></li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.banner {
    width: 100%;
    object-fit: cover;
    transform: scale(1.01);
}

.card-img-home {
    /*background-color: rgb(43, 124, 187) !important;*/
    border-radius: 10px !important;
}
</style>
