<template>
    <v-navigation-drawer app permanent expand-on-hover :rail="rail">
        <v-list dense>
            <v-list-item v-for="item in menuItems" :key="item.title" :to="item.route" :exact="true" class="menu-item"
                :class="{ 'menu-active menu-active-tonal': $route.path === item.route }" v-tooltip:end="item.tooltip">
                <template v-slot:prepend>
                    <v-icon>{{ item.icon }}</v-icon>
                </template>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
        </v-list>

        <template v-slot:append>
            <div class="pa-2">
                <v-btn block class="opacity-70" color="secondary" variant="tonal" prepend-icon="mdi mdi-logout-variant"
                    v-tooltip:end="'Cerrar Sesión'" @click="logout">
                    {{ !rail ? 'Cerrar Sesión' : '' }}
                </v-btn>
            </div>
        </template>
    </v-navigation-drawer>
</template>

<script>
import menuItems from '@/menu';

export default {
    name: 'NavBar',
    data() {
        return {
            menuItems,
        };
    },
    props: {
        rail: {
            type: Boolean,
            required: true,
        }
    },
    methods: {
        logout() {
            this.$emit('logout');
        }
    }
};
</script>

<style scoped>
.menu-item.v-list-item--active,
.menu-item.menu-active {
    background-color: var(--twitter-color);
    color: #ffffff;
}

.menu-item.menu-active-tonal {
    background-color: transparent;
    color: var(--twitter-color);
    opacity: .9;
}

.menu-item.menu-item:hover {
    color: var(--twitter-color);
}

.menu-item:hover {
    color: var(--twitter-color);
}
</style>
